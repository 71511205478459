import React from 'react'

export default function Footer() {
  return (
    <footer style={footerStyling}>
      Written in ReactJS.
    </footer>
  )
}

const footerStyling = {
  background: '#4f4f4f',
  color: '#fff',
  padding: '10px'
}
